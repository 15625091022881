const form = document.querySelector("form")!;
const statusText = document.querySelector<HTMLElement>("#status")!;

if (new URLSearchParams(document.location.search).get("success") !== null) {
  form.remove();
  statusText.innerText = "Thank you!";
}

// eslint-disable-next-line @typescript-eslint/no-misused-promises
form.addEventListener("submit", async (ev) => {
  ev.preventDefault();
  form
    .querySelectorAll<HTMLInputElement | HTMLButtonElement>("input, button")
    .forEach((el) => {
      el.disabled = true;
    });
  const data = new URLSearchParams({
    email: form.querySelector("input")?.value || "",
  });
  const res = await fetch("/api/signup", {
    method: "post",
    body: data,
  });
  if (res.ok) {
    form.remove();
    statusText.innerText = "Thank you!";
  } else {
    form
      .querySelectorAll<HTMLInputElement | HTMLButtonElement>("input, button")
      .forEach((el) => {
        el.disabled = false;
      });
    statusText.classList.add("error");
    statusText.innerText = "Something went wrong, try again?";
  }
});
